.footer {
    background-color: #f9f9f9;
    padding: 20px;
    text-align: center;
    font-size: 0.9rem;
    border-top: 1px solid #ddd;
  
    p {
      margin: 0;
      color: #555;
      line-height: 1.5;
  
      strong {
        color: #333;
      }
    }
  }
  