.add-controller {
    padding: 20px;
  
    .controller-style-selection {
      margin: auto;
      max-width: 800px;
    }
  
    .title {
      font-size: 1.5rem;
      margin-bottom: 20px;
      text-align: center;
    }
  
    .filter-container {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      label {
        font-size: 1rem;
        margin-bottom: 10px;
      }
  
      .multi-select {
        width: 50%;
        height: 150px;
        padding: 10px;
        font-size: 1rem;
      }
  
      .filter-button {
        margin-top: 10px;
        padding: 10px 15px;
        font-size: 1rem;
        cursor: pointer;
      }
    }
  
    .results-container {
      .result-group {
        margin-bottom: 30px;
  
        h5 {
          font-size: 1.2rem;
          margin-bottom: 10px;
        }
      }
  
      .no-results {
        font-size: 1rem;
        color: #666;
        text-align: center;
      }
    }
      
.filter-container {
    margin-bottom: 20px;

    label {
        font-size: 1rem;
        margin-right: 10px;
    }

    .multi-select {
        width: 100%;
        max-width: 400px;
        height: 120px;
        padding: 10px;
        font-size: 1rem;
    }

    .filter-button {
        margin-left: 10px;
        padding: 10px 15px;
        font-size: 1rem;
        cursor: pointer;
    }
}

.result-group {
    margin-bottom: 30px;

    h5 {
        margin-bottom: 10px;
        font-size: 1.2rem;
    }
}

.no-results {
    font-size: 1rem;
    color: #666;
}

ul {
    list-style-type: none;
    padding: 0;

    li {
        margin-bottom: 15px;

        strong {
            display: block;
            font-weight: bold;
        }

        a {
            color: #007bff;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}

.filter-container {
  margin-bottom: 20px;

  label {
    font-size: 1rem;
    margin-right: 10px;
  }

  .multi-select {
    width: 100%;
    max-width: 400px;
    height: 120px;
    padding: 10px;
    font-size: 1rem;
  }

  .filter-button {
    margin-left: 10px;
    padding: 10px 15px;
    font-size: 1rem;
    cursor: pointer;
  }
}

.results-container {
  margin-top: 20px;

  .result-group {
    margin-bottom: 30px;

    h5 {
      margin-bottom: 10px;
      font-size: 1.2rem;
    }
  }

  .no-results {
    font-size: 1rem;
    color: #666;
  }
}
}
