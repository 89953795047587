.app-page {
    text-align: center;
    margin-top: 50px;
    padding: 20px; 
    
    h1 {
      font-size: 2.5rem;
      margin-bottom: 20px;
    }
  
    p {
      font-size: 1.2rem;
      margin-bottom: 30px;
    }
  
    .button-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 30px;
  
      .large-button {
        background-color: #007bff;
        color: #fff;
        padding: 20px 30px;
        border-radius: 8px;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: bold;
        transition: background-color 0.3s ease;
        text-align: center;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  